import React, { FunctionComponent } from "react";
import { ExternalLinkIcon } from "../../images/icons/externalLinkIcon";
import Accessibility from "../Accessibility";

export interface ExternalLinkInterface {
  location?: string;
  link: string;
  name: string;
  nofollow?: boolean;
  noopener?: boolean;
  markKey?: string;
  hasIcon?: boolean;
}

const ExternalLink: FunctionComponent<ExternalLinkInterface> = ({
  location,
  link,
  name,
  nofollow,
  noopener,
  markKey,
  hasIcon = true
}) => {
  const noFollow = nofollow ? "nofollow" : "";
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a className="external-link" href={link} rel={`noreferrer ${!noopener} ${noFollow}`} key={markKey}>
      {" "}
      {name}
      {location ? <Accessibility location={location} openNewWindow={true} /> : <Accessibility openNewWindow={true} />}
      {hasIcon && <ExternalLinkIcon />}
    </a>
  );
};

export default ExternalLink;
