import React, { FunctionComponent } from "react";
import RightArrowIcon from "../../images/icons/rightArrowIcon";

import "./styles.scss";

interface fromToInterface {
  amount: string;
  label: string;
}

interface FromToBlockInterface {
  from: fromToInterface;
  to: fromToInterface;
}

const FromToBlock: FunctionComponent<FromToBlockInterface> = props => {
  const { from, to } = props;
  return (
    <div className="fromToBlock__wrapper">
      <div className="fromToBlock__fromSection">
        <p>from</p>
        <h2 className="fromToBlock__amount">${from.amount}</h2>
        <p className="fromToBlock__label">{from.label}</p>
      </div>
      <div className="fromToBlock__icon">
        <RightArrowIcon />
      </div>
      <div className="fromToBlock__toSection">
        <p>to</p>
        <h2 className="fromToBlock__amount">${to.amount}</h2>
        <p className="fromToBlock__label">{to.label}</p>
      </div>
    </div>
  );
};

export default FromToBlock;
