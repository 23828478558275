import React, { FunctionComponent } from "react";
import classNames from "classnames";

import "./styles.scss";

interface SectionProps {
  title?: string;
  description?: string;
  theme?: string;
  sectionClassName?: string;
  style?: any;
  width?: string;
  subHeading?: string;
  titleAsH1?: boolean;
}

const Section: FunctionComponent<React.PropsWithChildren<SectionProps>> = props => {
  const { title, description, theme, sectionClassName, style, width, subHeading, children, titleAsH1 } = props;

  return (
    <section
      className={classNames(
        "c-section spb-md-64 spb-xs-36 spt-md-64 spt-xs-36 ",
        theme && `${theme.toLocaleLowerCase()}--theme`,
        sectionClassName
      )}
      style={style}
    >
      <div className={`layout-container ${width ? width : ""}`}>
        {title ? (
          <header>
            {titleAsH1 ? (
              <h1 className="c-section__title-h1">
                {title}
                {subHeading && <span className="c-section__subtitle">{subHeading}</span>}
              </h1>
            ) : (
              <h2 className="c-section__title">
                {title}
                {subHeading && <span className="c-section__subtitle">{subHeading}</span>}
              </h2>
            )}

            {description ? <p className="c-section__description">{description}</p> : null}
          </header>
        ) : null}
        {children}
      </div>
    </section>
  );
};

export default Section;
