import React, { FunctionComponent } from "react";
import { SanityCtaBlock } from "../../types/SanityTypes";
import { Link } from "../Link";

export interface CtaBlockInterface {
  ctaBlock: SanityCtaBlock;
  ctaLabel?: string;
  linkClassName?: string;
  children?: any;
  onClickFunction?: any;
}

const CtaBlock: FunctionComponent<CtaBlockInterface> = ({
  children,
  ctaBlock,
  ctaLabel,
  linkClassName,
  onClickFunction
}) => {
  const getLink = (ctaBlock: SanityCtaBlock): string => {
    let link = "";
    if (ctaBlock.isExternalLink) {
      link = ctaBlock.externalCtaLink || "";
    } else {
      if (ctaBlock._rawCtaLink && ctaBlock._rawCtaLink._type) {
        switch (ctaBlock._rawCtaLink._type) {
          case "advancedTextPage":
            link = `${ctaBlock._rawCtaLink.parentSection?.slug?.current || ""}/${ctaBlock._rawCtaLink.slug.current}`;
            break;
          case "collection":
            link = `collection/${ctaBlock._rawCtaLink.slug.current}`;
            break;
          case "category":
            link = `${ctaBlock._rawCtaLink.parentCategory?.slug?.current || ""}/${ctaBlock._rawCtaLink.slug.current}`;
            break;
          default:
            link = ctaBlock._rawCtaLink.slug.current;
        }
      } else link = ctaBlock.ctaLink?.slug?.current || "";
    }
    return link;
  };

  return (
    ctaBlock && (
      <Link
        to={getLink(ctaBlock)}
        className={linkClassName}
        isExternalLink={ctaBlock?.isExternalLink}
        ctaFile={ctaBlock?._rawCtaFile}
        ctaIcon={ctaBlock?._rawCtaIcon}
        label={ctaLabel}
        asButton={ctaBlock?.ctaBlockType}
        buttonSize={ctaBlock?.ctaBlockSize}
        buttonForm={ctaBlock?.ctaBlockForm}
        screenReaderText={ctaBlock?.ctaLabelScreenReader}
        onClickFunction={onClickFunction}
      >
        {children}
      </Link>
    )
  );
};

export default CtaBlock;
