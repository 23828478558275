import React, { FunctionComponent } from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import SanityImage from "gatsby-plugin-sanity-image";
import RichText from "../RichText";

import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

interface ImageTextBlockIconInterface {
  align?: boolean;
  list: [
    {
      label?: any;
      icon: ImageInterface;
      labelDisplayInline?: boolean;
    }
  ];
}

const ImageTextBlockIcon: FunctionComponent<ImageTextBlockIconInterface> = props => {
  const { list, align } = props;
  const imageAlignClassName = align ? "center" : "default";
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  return (
    <div className={`imageIconBlock_wrapper imageIconBlock_wrapper-${imageAlignClassName}`}>
      {list.map((listItem, index) => (
        <div
          className={`imageIconBlock_item  ${listItem.labelDisplayInline ? "imageIconBlock_item-inline" : ""}`}
          key={index}
        >
          <SanityImage
            sizes={IMAGE_SIZES.SMALL_SQUARE}
            {...listItem.icon}
            width={100}
            height={100}
            htmlWidth={100}
            htmlHeight={100}
            className="imageIconBlock_item_image"
          />
          {listItem.label ? <RichText data={listItem.label} /> : null}
        </div>
      ))}
    </div>
  );
};

export default ImageTextBlockIcon;
