import React, { FunctionComponent, useRef, useState } from "react";
import { Fallback, WebM } from "../../types/SanityTypes";
import { PlayVideo } from "../../images/icons/play-video";
import { PauseVideo } from "../../images/icons/pause-video";
import LazyLoad from "react-lazy-load";

import "./styles.scss";

interface VideoBlockInterface {
  webm?: WebM;
  fallback?: Fallback;
  aspectratio?: number;
  width?: string;
  height?: string;
  alt?: string;
  caption?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  classnames?: string;
  hasPauseButton?: boolean;
}

const VideoBlock: FunctionComponent<VideoBlockInterface> = ({
  webm,
  fallback,
  width,
  height,
  alt,
  autoPlay = true,
  loop = true,
  muted = true,
  classnames,
  hasPauseButton = false
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState<boolean>(!autoPlay);

  if (!webm || !fallback) {
    return null;
  }
  const videoWidth = width || "100%";
  const videoHeight = height || "100%";

  const handleVideo = () => {
    videoRef && videoRef.current && (isPaused ? videoRef.current.play() : videoRef.current?.pause());
    setIsPaused(!isPaused);
  };

  return (
    <>
      <LazyLoad height="100%">
        <video
          ref={videoRef}
          title={alt}
          className={classnames}
          width={videoWidth}
          height={videoHeight}
          autoPlay={autoPlay}
          loop={loop}
          muted={muted}
          playsInline
        >
          {webm && webm.asset?.url && <source src={webm.asset.url} type={webm.asset?.mimeType} />}
          {fallback && fallback.asset.url && <source src={fallback.asset.url} type={fallback.asset.mimeType} />}
        </video>
      </LazyLoad>
      {hasPauseButton && (
        <button
          type="button"
          data-play="Play"
          data-pause="Pause"
          className="btn-playPause"
          onClick={handleVideo}
          aria-label={isPaused ? "Play" : "Pause"}
        >
          {isPaused ? <PlayVideo /> : <PauseVideo />}
        </button>
      )}
    </>
  );
};

export default VideoBlock;
