import React from "react";

function RightArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="16" fill="none" viewBox="0 0 58 16">
      <path
        fill="#94514E"
        d="M57.707 8.707a1 1 0 000-1.414L51.343.929a1 1 0 10-1.414 1.414L55.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h57V7H0v2z"
      ></path>
    </svg>
  );
}

export default RightArrowIcon;
