import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Instagram } from "web-common/src/images/icons/instagram-sketch.svg";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import { useInView } from "react-intersection-observer";
import { MultiLineHeadline } from "../../types/SanityTypes";
import { useStaticQuery, graphql } from "gatsby";
import Accessibility from "../Accessibility";
import ExternalLink from "../ExternalLink";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";

type Image = {
  url: string;
};
type Post = {
  objectid: string;
  images: Image[];
  text: string;
  uid: string;
};

export interface SocialWallInterface {
  folder: string;
  socialLink: {
    ctaURL: string;
    ctaLabel: string;
  }[];
  headline: MultiLineHeadline;
}

const SocialWall: FunctionComponent<SocialWallInterface> = ({ folder, socialLink, headline }) => {
  const data = useStaticQuery(graphql`
    query socialWall {
      instagram: sanitySocialMediaChannel(channel: { eq: "Instagram" }) {
        url
      }
    }
  `);

  const { sanityMiappi } = useContext(LocalizedContext);
  const { miappiClientId } = useSiteMetadata();
  const [posts, setPosts] = useState([] as Array<Post>);

  const [socialWallRef, socialWallinView] = useInView({
    triggerOnce: true
  });

  useEffect(() => {
    fetch(`https://data.miappi.com/clients/v1/${miappiClientId}/feed?folders=${folder}&limit=3`)
      .then(response => response.json())
      .then(response => response.data)
      .then(data => setPosts(data));
  }, [folder, miappiClientId]);

  const renderImageItems = () => {
    return posts !== []
      ? posts.map((post: Post, index: number) => {
          if (posts) {
            const cardPosition = "post_" + String(index);
            return (
              <li className={cardPosition + " link-position"} key={post.objectid}>
                <a href={data.instagram?.url} rel="noopener noreferrer">
                  <source media={"(min-width: 768px)"} src={post.images[0].url + "&width=396px&height=396"} />
                  <img
                    loading={"lazy"}
                    width="495px"
                    height="495px"
                    src={post.images[0].url + "&width=484px&height=484"}
                    alt={post.text}
                    className="img-fluid"
                  />
                  <Accessibility openNewWindow={true} />
                </a>
              </li>
            );
          }
        })
      : null;
  };

  const renderSocialLinks = () => {
    return socialLink.map(item => {
      return (
        <li key={item.ctaLabel}>
          <ExternalLink link={item.ctaURL} name={item.ctaLabel} noopener={true} nofollow={false} hasIcon={false} />
        </li>
      );
    });
  };

  return (
    <section className="social-wall" data-testid="social-wall">
      <Container fluid>
        <Row>
          <Col>
            <div className="copy-and-posts">
              <div className="social-copy">
                <h2
                  aria-label={
                    headline.primaryText + " " + (headline.secondaryText || "") + " " + (headline.tertiaryText || "")
                  }
                >
                  {headline.primaryText}{" "}
                  <span aria-hidden="true">{headline.secondaryText && headline.secondaryText}</span>
                  {headline.tertiaryText && headline.tertiaryText}
                </h2>
                <ul>{socialLink && renderSocialLinks()}</ul>
                <Instagram />
              </div>
              <ul className="posts" data-testid="social-wall-image" ref={socialWallRef}>
                {socialWallinView && renderImageItems()}
              </ul>
              {sanityMiappi && (
                <div className="miappi">
                  <ExternalLink
                    link={"https://miappi.com/"}
                    name={sanityMiappi.miappiTag}
                    noopener={true}
                    nofollow={false}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SocialWall;
