import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import RichText from "../RichText";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ImageInterface, PortableText, SanityCtaBlock, VideoInterface } from "../../types/SanityTypes";
import CtaBlock from "../CtaBlock";
import Section from "../Section";
import "./styles.scss";
import VideoBlock from "../VideoBlock";
import { IMAGE_SIZES } from "../../constants";

interface ImageTextBlockInterface {
  _id: string;
  imageBlock?: {
    _rawImage: ImageInterface;
    image?: ImageInterface;
    isVideo?: boolean;
    _rawVideo?: VideoInterface;
    imageProportions?: string;
    isImageFullWidth?: boolean;
    _rawText: PortableText;
    text?: PortableText;
    textPosition: string;
    livingInMyRich: boolean;
    tag: string;
    _rawCharacteristics: PortableText;
  };
  title?: string;
  palleteColorList: {
    bgColor?: {
      title: string;
      value: string;
    };
  };
  ctaBlock?: SanityCtaBlock;
  secondaryCtaBlock?: SanityCtaBlock;
  _rawDecorativeImage?: ImageInterface;
}

const ImageTextBlock: FunctionComponent<ImageTextBlockInterface> = props => {
  const { imageBlock, title = "", palleteColorList, ctaBlock, secondaryCtaBlock, _rawDecorativeImage } = props;
  const bgClassName = palleteColorList?.bgColor?.title || "default";
  const video = imageBlock?._rawVideo;
  const imageProportions = imageBlock?.imageProportions;
  const textPosition = imageBlock?.textPosition;
  const sanityImage = imageBlock?.image as ImageInterface;
  const text = (imageBlock?._rawText || imageBlock?.text) as PortableText;
  const tag = imageBlock?.tag;
  const characteristics = imageBlock?._rawCharacteristics;

  const livingInMyRich = imageBlock?.livingInMyRich;

  const tagWholeBodyDeorant = tag === "WHOLE BODY DEODORANT";

  const [imageWidth, imageHeight] =
    imageBlock && imageBlock.imageProportions == "square"
      ? [690, 690]
      : imageBlock && imageBlock.imageProportions == "rectangle"
      ? [700, 900]
      : [690, 520];

  //animation
  const animatedElement = useRef<HTMLDivElement>(null);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const checkVisibility = () => {
    if (animatedElement.current) {
      const positionFromBottom = animatedElement.current.getBoundingClientRect().bottom;
      if (positionFromBottom - window.innerHeight <= 0) {
        setIsCardVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    window.addEventListener("resize", checkVisibility);
    return () => {
      window.removeEventListener("scroll", checkVisibility);
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);

  return (
    <Section
      sectionClassName={`image-text-block ${props._id} ${
        imageBlock?.isImageFullWidth ? "image-text-block--full-width-image" : ""
      }`}
      title={title}
      theme={bgClassName}
    >
      <Row>
        <Col
          lg={6}
          md={12}
          className={`image-asset-block ${
            textPosition === "left" ? "order-lg-2 image-asset-block-right" : "image-asset-block-left"
          } ${_rawDecorativeImage ? "image-asset-block--with-decor" : ""}`}
        >
          {imageBlock?.isVideo ? (
            <div className={`image-asset-block_wrapper aspect-ratio_${imageProportions ? imageProportions : ""}`}>
              <VideoBlock
                classnames="image-asset-block-video"
                webm={video?.webm}
                fallback={video?.fallback}
                alt={video?.alt}
                autoPlay
                muted
                loop
              />
            </div>
          ) : sanityImage && sanityImage?.asset ? (
            <div className={`image-asset-block_wrapper aspect-ratio_${imageProportions ? imageProportions : ""}`}>
              <SanityImage
                {...sanityImage}
                className="image-asset-block-picture-responsive image-asset-block-picture-rounded"
                sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
                width={imageWidth}
                height={imageHeight}
                htmlWidth={imageWidth}
                htmlHeight={imageHeight}
              />
            </div>
          ) : null}
          {_rawDecorativeImage && _rawDecorativeImage.asset ? (
            <div className={`image-asset-block-decor ${isCardVisible ? "animated" : ""}`} ref={animatedElement}>
              <SanityImage
                {..._rawDecorativeImage}
                className="image-asset-block-decor__image"
                sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK_SMALL}
                width={622}
                height={622}
                htmlWidth={622}
                htmlHeight={622}
              />
            </div>
          ) : null}
        </Col>
        <Col
          lg={6}
          md={12}
          data-testid="text-right"
          className={`${tag ? `image-text-block-content_wrapper-gray` : `image-text-block-content_wrapper`} ${
            textPosition === "left" ? "order-lg-1 image-text-block-left" : "image-text-block-right"
          }`}
        >
          {livingInMyRich && tag && (
            <p
              className={
                tagWholeBodyDeorant
                  ? "image-text-block-content_wrapper__tagBlue"
                  : "image-text-block-content_wrapper__tagOrange"
              }
            >
              {tag}
            </p>
          )}
          {livingInMyRich ? (
            <h2 className="image-text-block-content_wrapper__text">
              <RichText data={text} />
            </h2>
          ) : (
            <RichText data={text} />
          )}
          {livingInMyRich && characteristics && (
            <div className="image-text-block-content_wrapper__characteristics">
              <RichText data={characteristics} />
            </div>
          )}

          <div className="image-text-block-content-cta__wrapper">
            {ctaBlock && ctaBlock.ctaLabel && (
              <CtaBlock
                ctaBlock={ctaBlock}
                ctaLabel={ctaBlock?.ctaLabel}
                linkClassName="image-text-block-content_link"
              />
            )}
            {secondaryCtaBlock && secondaryCtaBlock.ctaLabel && (
              <CtaBlock
                ctaBlock={secondaryCtaBlock}
                ctaLabel={secondaryCtaBlock?.ctaLabel}
                linkClassName="image-text-block-content_link"
              />
            )}
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default ImageTextBlock;
